var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"手机号码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'phone',
          {
            rules: [
              {
                required: true,
                message: '请输入您的手机号码',
              } ],
          } ]),expression:"[\n          'phone',\n          {\n            rules: [\n              {\n                required: true,\n                message: '请输入您的手机号码',\n              },\n            ],\n          },\n        ]"}],attrs:{"type":"number","placeholder":"将用作企业官媒平台登录账户"},on:{"blur":_vm.handleConfirmBlur}})],1),_c('div',{staticClass:"Tips"},[_vm._v(" 请填写未在企业官媒平台注册的手机号码，每个手机号只能申请一个企业官媒平台 ")]),_c('a-form-item',_vm._b({attrs:{"label":"验证码"}},'a-form-item',_vm.formItemLayout,false),[_c('a-row',{attrs:{"gutter":8}},[_c('a-col',{attrs:{"span":16}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'captcha',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入6位验证码',
                    min: 6,
                  } ],
              } ]),expression:"[\n              'captcha',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请输入6位验证码',\n                    min: 6,\n                  },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"请输入验证码"}})],1),(_vm.showtime === null)?_c('a-col',{attrs:{"span":2}},[_c('a-button',{staticStyle:{"color":"#0a49ff"},on:{"click":_vm.send}},[_vm._v("获取验证码")])],1):_c('a-col',{attrs:{"span":2}},[_c('a-button',[_vm._v(" "+_vm._s(_vm.showtime))])],1)],1)],1),_c('a-form-item',_vm._b({},'a-form-item',_vm.tailFormItemLayout,false),[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'agreement',
          {
            rules: [
              {
                required: true,
                message: '请勾选且同意《企业官媒用户协议》',
              } ],
          } ]),expression:"[\n          'agreement',\n          {\n            rules: [\n              {\n                required: true,\n                message: '请勾选且同意《企业官媒用户协议》',\n              },\n            ],\n          },\n        ]"}]},[_vm._v(" 我同意并遵守 "),_c('a',{attrs:{"href":"https://view.officeapps.live.com/op/view.aspx?src=https://video-1300439097.cos.ap-chongqing.myqcloud.com/school/企业官媒平台服务协议.docx"}},[_vm._v(" 《企业官媒平台服务协议》 ")])])],1),_c('a-form-item',_vm._b({},'a-form-item',_vm.tailFormItemLayout,false),[_c('a-button',{staticClass:"buttom",attrs:{"block":"","html-type":"submit"}},[_vm._v(" 申请认证 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }