import { render, staticRenderFns } from "./mobilepay.vue?vue&type=template&id=df620a68&scoped=true&"
import script from "./mobilepay.vue?vue&type=script&lang=js&"
export * from "./mobilepay.vue?vue&type=script&lang=js&"
import style0 from "./mobilepay.vue?vue&type=style&index=0&id=df620a68&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df620a68",
  null
  
)

export default component.exports