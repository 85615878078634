var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin":"10.6667vw 5.3333vw 0 5.3333vw","width":"93vw"}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"平台注册类型","has-feedback":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'select',
            {
              rules: [{ required: true, message: '请选择注册类型!' }],
            } ]),expression:"[\n            'select',\n            {\n              rules: [{ required: true, message: '请选择注册类型!' }],\n            },\n          ]"}],attrs:{"placeholder":"请输入平台类型"}},[_c('a-select-option',{attrs:{"value":"enterprise"}},[_vm._v(" 企业 ")]),_c('a-select-option',{attrs:{"value":"personal"}},[_vm._v(" 个人 ")])],1)],1),_c('a-form-item',_vm._b({attrs:{"label":"平台名称"}},'a-form-item',_vm.formItemLayout,false),[_c('a-row',{attrs:{"gutter":8}},[_c('a-col',{attrs:{"span":19}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'captcha',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入您的平台名称',
                    } ],
                } ]),expression:"[\n                'captcha',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入您的平台名称',\n                    },\n                  ],\n                },\n              ]"}]})],1),_c('a-col',{attrs:{"span":2}},[_c('a-button',{on:{"click":_vm.enterCaptcha}},[_vm._v("核验")])],1)],1),_c('div',{staticStyle:{"font-size":"1.8667vw","color":"#737373"}},[_vm._v(" 请填写未在企业官媒平台认证的名称，一个手机号仅注册一个企业官媒平台名称 ")]),_c('div',{staticStyle:{"color":"red","font-size":"2.6667vw"}},[_vm._v(" "+_vm._s(_vm.cxRes)+" ")])],1),_c('a-form-item',_vm._b({attrs:{"label":"平台年限"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
	        'year',
	        {
	          rules: [
	            {
	              required: true,
	              message: '请输入您的平台年限',
	            } ],
	        } ]),expression:"[\n\t        'year',\n\t        {\n\t          rules: [\n\t            {\n\t              required: true,\n\t              message: '请输入您的平台年限',\n\t            },\n\t          ],\n\t        },\n\t      ]"}]})],1),_c('a-form-item',{attrs:{"label":"行业","has-feedback":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'trade',
            {
              rules: [{ required: true, message: '请选择您的行业' }],
            } ]),expression:"[\n            'trade',\n            {\n              rules: [{ required: true, message: '请选择您的行业' }],\n            },\n          ]"}],attrs:{"placeholder":"请选择行业"}},_vm._l((_vm.industry),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),(_vm.form.getFieldValue('select') === 'enterprise')?_c('div',[_c('div',{staticStyle:{"font-size":"1.8667vw","color":"#1a1a1a"}},[_vm._v("企业信息")]),_c('div',{staticStyle:{"width":"83.3333vw","height":"0.1333vw","background":"#e4e8eb","margin-top":"1.333vw"}}),_c('a-form-item',{staticStyle:{"margin-top":"5.3333vw"},attrs:{"label":"公司名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'corporateName',
              { rules: [{ required: true, message: '请输入您的公司名称' }] } ]),expression:"[\n              'corporateName',\n              { rules: [{ required: true, message: '请输入您的公司名称' }] },\n            ]"}],attrs:{"placeholder":"请输入公司名称"}})],1),_c('a-form-item',{attrs:{"label":"注册人名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'registrant',
              { rules: [{ required: true, message: '请输入注册人名称' }] } ]),expression:"[\n              'registrant',\n              { rules: [{ required: true, message: '请输入注册人名称' }] },\n            ]"}],attrs:{"placeholder":"请输入注册人名称"}})],1)],1):_vm._e(),(_vm.form.getFieldValue('select') === 'personal')?_c('div',[_c('div',{staticStyle:{"font-size":"1.8667vw","color":"#1a1a1a"}},[_vm._v("个人信息")]),_c('div',{staticStyle:{"width":"83.3333vw","height":"0.1333vw","background":"#e4e8eb","margin-top":"1.333vw"}}),_c('a-form-item',{staticStyle:{"margin-top":"5.3333vw"},attrs:{"label":"注册人名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'registrant',
              { rules: [{ required: true, message: '请输入注册人名称' }] } ]),expression:"[\n              'registrant',\n              { rules: [{ required: true, message: '请输入注册人名称' }] },\n            ]"}],attrs:{"placeholder":"请输入注册人名称"}})],1)],1):_vm._e(),_c('a-button',{staticClass:"button",staticStyle:{"background":"#ffffff"},on:{"click":_vm.enterback}},[_vm._v(" 上一步 ")]),_c('a-button',{staticClass:"button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 提交信息 ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }